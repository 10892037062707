<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1 card-sipymex">
        <v-row>
          <v-col cols="12" md="9">
            <span class="white--text">Cuentas por pagar</span>
          </v-col>
          <v-col cols="12" md="3"> </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <br />
        <div>
          <v-alert v-model="alert" color="primary" elevation="2" colored-border>
            <v-row>
              <v-col cols="12" md="3">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-store</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-progress-linear v-if="loaders"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
                      <v-list-item-title>{{ legal_name }}
                       
</v-list-item-title>
                      <v-list-item-subtitle>Razón Social</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="4">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo">mdi-card-text-outline </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-progress-linear v-if="loaders"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
                      <v-list-item-title>{{ rut }}</v-list-item-title>
                      <v-list-item-subtitle>RUT</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="4">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-store-outline </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-progress-linear v-if="loaders"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
                      <v-list-item-title>{{ trade_name }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Nombre de Fantasía</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item></v-list
                >
              </v-col>
            </v-row>
          </v-alert>
        </div>

        <br />

        <v-data-table
          :headers="headers"
          :items="debts"
          :search="search"
          :footer-props="{
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'todos',
          }"
          no-data-text="No hay datos disponibles"
          no-results-text="No hay datos disponibles"
        >
          <template v-slot:item.billing_period="{ item }">
            <span>{{
              getDayBillingPeriodsByIdContract(item.id_contract)
            }}</span>
          </template>

          <template v-slot:item.name_plan="{ item }">
            <span>{{ getNamePlanByIdContract(item.id_contract) }}</span>
          </template>

          <template v-slot:item.price="{ item }">
            <span> <strong>$ </strong> {{ item.price }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :to="'/update-debt/' + item.id">
                  <v-icon v-bind="attrs" color="primary" v-on="on">
                    mdi-pencil-box-multiple
                  </v-icon>
                </v-btn>
              </template>

              <span>Modificar</span>
            </v-tooltip>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                  <v-card-text>
                    <div class="text-h7 pa-6">
                      ¿Estás seguro que deseas eliminar la deuda?
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                    <v-btn
                      class="primary"
                      text
                      @click="
                        dialog.value = false;
                        deleteItem(item);
                      "
                      >Aceptar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
        </v-data-table>

        <div>
          <v-alert
            v-model="alert"
            color="primary"
            border="top"
            elevation="2"
            colored-border
          >
            <div class="float-right">
              Total a pagar: <strong>${{ mount_debt }}</strong>
            </div>
          </v-alert>
        </div>

        <v-row>
          <v-col cols="12" md="9">
            <span class="white--text">Deudas</span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="m-20 float-right">
              <v-btn
                class="white--text"
                color="primary"
                @click="payByIdCustomer()"
                :disabled="btn_disable"
                outlined
              >
                <v-icon small dark> mdi-plus-circle </v-icon> Pagar
              </v-btn>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    dialog: false,
    loaders:true,
    search: "",
    headers: [
      { text: "Plan", value: "name_plan" },
      { text: "Día de Facturación", value: "billing_period" },
      { text: "Monto de Deuda", value: "price" },
      { text: "Fecha de Deuda", value: "date" },
    ],

    alert: "",
    legal_name: "",
    trade_name: "",
    rut: "",
    record_number: "",
    address: "",
    mail: "",
    phone: "",
    comune: "",
    mount_debt: "",
    btn_disable: true,

    debts: [],
    contracts: [],
    plans: [],
    billing_periods: [],

    id_contract: "",
  }),

  computed: {},
  mounted() {
    this.action = "Eliminar";
    this.getCustomerById();
    this.getDebtsById();
    this.getMountDebtsById();
    this.getPlans();
    this.getBillingPeriods();
    this.getContracts();
  },

  watch: {},

  methods: {
    getCustomerById() {
      let request = {
        id: this.$route.params.id,
      };

      axios
        .post("/customer", request)
        .then((response) => {
          console.log("Cliente ->");
          console.log(response.data);
          this.legal_name = response.data["legal_name"];
          this.trade_name = response.data["trade_name"];
          this.rut = response.data["rut"];
          this.record_number = response.data["legal_name"];
          this.address = response.data["address"];
          this.mail = response.data["mail"];
          this.phone = response.data["phone"];
          this.comune = response.data["comune"];
          this.loaders=false;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener cliente"
          );
        });
    },
    getDebtsById() {
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/get-debts-by-id", request)
        .then((response) => {
          console.log("Deudas ->");
          console.log(response.data);
          this.debts = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener deudas de cliente"
          );
        });
    },
    getMountDebtsById() {
      let request = {
        id: this.$route.params.id,
      };
      console.log("Obtener monto total de las deudas asociadas a un cliente");
      axios
        .post("/get-total-amount-debts-by-id", request)
        .then((response) => {
          console.log("Total amount ->");
          console.log(response.data);
          this.mount_debt = response.data;
          if (this.mount_debt > 0) {
            this.btn_disable = false;
          }
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener el monto total de deuda"
          );
        });
    },
    getContracts() {
      axios
        .get("/contracts")
        .then((response) => {
          console.log(response.data);
          this.contracts = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obener contratos"
          );
        });
    },
    getContractsByIdCustomer() {
      let request = {
        id: this.$route.params.id,
      };
      axios
        .get("/get-contracts-by-customer", request)
        .then((response) => {
          console.log(response.data);
          this.contracts = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener contratos"
          );
        });
    },

    getBillingPeriods() {
      axios
        .get("/billing-periods")
        .then((response) => {
          console.log("Imprimiendo BillingPeriods");
          console.log(response.data);
          this.billing_periods = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener día de facturación"
          );
        });
    },

    getPlans() {
      axios
        .get("/plans")
        .then((response) => {
          console.log("Plans");
          console.log(response.data);
          this.plans = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener planes");
        });
    },

    getContractById(id_contract) {
      let contract = this.contracts.find((c) => c.id === id_contract);
      return contract;
    },

    getDayBillingPeriodsByIdContract(id_contract) {
      let contract = this.getContractById(id_contract);
      let name = this.getDayBillingPeriod(contract.id_billing_periods);
      console.log("getDayBillingPeriodsByIdContract");
      console.log("contract.id_billing_periods ");
      console.log(name);
      return name;
    },
    getDayBillingPeriod(id_billing_period) {
      console.log("BillingPeriodName");
      console.log(id_billing_period);
      let billing = this.billing_periods.find(
        (billing) => billing.id === id_billing_period
      );

      return billing.billing_period;
    },

    getNamePlan(id_plan) {
      //  this.getServices();
      let plan = this.plans.find((plan) => plan.id === id_plan);

      return plan.name;
    },

    getNamePlanByIdContract(id_contract) {
      let contract = this.getContractById(id_contract);
      let name = this.getNamePlan(contract.id_plan);
      return name;
    },

    payByIdCustomer() {
      console.log("payByIdCustomer");
      let request = {
        id: this.$route.params.id,
      };
      console.log("Realizar pago de deudas por id cliente");
      axios
        .post("/pay-debt", request)
        .then((response) => {
          console.log("Pagos ->");
          window.location.href =
            response.data.url + "?token=" + response.data.token;
          console.log(response.data);
          console.log("Pago exitoso");
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al realizar el pago"
          );
        });
    },
  },
};
</script>